import './App.css';
import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";

import HomePage from './components/HomePage';
import Contact from './components/Contact';
import TermsandCondition from './components/TermsandCondition.jsx';
import PrivacyPolicy from './components/PrivacyPolicy.jsx';
import LegalNotice from './components/LegalNotice.jsx';
import LogContext from './context/LogContext.js';
import BPS from './components/Services/Products/BPS.jsx';
import OBCC from './components/Services/Products/OBCC.jsx';
import IBCC from './components/Services/Products/IBCC.jsx';
import Pricing from './components/Pricing.jsx';
import MainLayout from './layouts/MainLayout.jsx'
import MinimalLayout from './layouts/MinimalLayout.jsx';


import Login from './components/Login.jsx';
import GoogleForm from './components/GoogleForm.jsx';

function App() {
  const myContext= useContext(LogContext);
  

  useEffect(()=>{
    myContext.updateCurrency(localStorage.getItem('currencyType'), localStorage.getItem('currencyRatio'));
  });
 
  return (
    <Router>
      <Routes>
      <Route path="/" element={<MainLayout><HomePage /></MainLayout>} />
        <Route path="/contact" element={<MinimalLayout><Contact /></MinimalLayout>} />
        <Route path="/login" element={<MinimalLayout><Login /></MinimalLayout>} />
        <Route path="/inbound-call-center" element={<MainLayout><IBCC /></MainLayout>} />
        <Route path="/outbound-call-center" element={<MainLayout><OBCC /></MainLayout>} />
        <Route path="/bps" element={<MainLayout><BPS /></MainLayout>} />
        <Route path="/t&c" element={<MainLayout><TermsandCondition /></MainLayout>} />
        <Route path="/privacypolicy" element={<MainLayout><PrivacyPolicy /></MainLayout>} />
        <Route path="/SLA" element={<MainLayout><LegalNotice /></MainLayout>} />
        <Route path="/pricing" element={<MainLayout><Pricing /></MainLayout>} />
        <Route path="/RGA" element={<MinimalLayout><GoogleForm /></MinimalLayout>} />
      </Routes>
    </Router>
  );
}

export default App;
