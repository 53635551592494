
import Newnav from './Newnav';
export default function Navbar() {
  return (<>
    <div>
      <Newnav />
    </div>
  </>
  )
}
