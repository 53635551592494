import React from 'react';
import AboutImage from '../img/care2.png';
import aboutImage from '../img/intelligently-assign-calls.svg'
export default function About() {
  return (
       <div className="container-fluid about py-5">
      <div className="container py-5">
        <div className="row g-5 align-items-center">
          <div className="col-lg-5">
            {/* <div className="h-100" style={{ border: '50px solid', borderColor: 'transparent  #0fc3fa transparent  #0fc3fa' }}>
              <img src={aboutImage} className="img-fluid w-100 h-100" alt=""  />
            </div> */}
            <div className=''><img src={aboutImage} className="img-fluid w-100 h-100" alt=""  /></div>
          </div>
          <div className="col-lg-7" style={{ background: 'linear-gradient(rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(img/about-img-1.png)' }}>
            <h5 className="section-about-title pe-3">Revolutionize Your Outreach with Seamless Dialing.</h5>
            <h1 className="mb-4">Know What <span className='fw-bolder fs-2' style={{color:' #0fc3fa',wordSpacing:'.1px',fontSize:'domine'}}>The Autodial</span> Is?</h1>
            <p className="mb-4">In today's fast-paced business environment, efficient communication is key to success. Our state-of-the-art dialer solution is designed to streamline your outbound calling process, enabling your team to connect with more clients in less time. Whether you're a small business looking to scale or a large enterprise aiming to optimize your outreach, our dialer solution offers the perfect blend of reliability, speed, and ease of use.</p>
            <p className="mb-4">Empowering your communication strategy with our dialer solution means more efficient calls, better customer interactions, and ultimately, higher success rates for your business.</p>
            <div className="row mt-5 gy-2 gx-4 mb-4">
              <div className="col-sm-6">
                <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i> Free Demo within 2Hrs.</p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>475+ Live Reference BPO's in 11 Countries</p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>1500+ Soft/Hard Dialer installations</p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>72Hrs. Refund Policy</p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Lower than your lowest bidder</p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Customizable Options</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
