import React from 'react';
import Marquee from "react-fast-marquee";
import testimonial1 from '../img/testimonial-1.jpg';
import testimonial2 from '../img/testimonial-2.jpg';
import testimonial3 from '../img/testimonial-3.jpg';
import testimonial4 from '../img/testimonial-4.jpg';
import { useLocation } from 'react-router-dom';

const Testimonial = () => {
  const location= useLocation();
  return (
    <>
        <div id="testimonial" className='container-fluid position-relative opacity-0' style={{top:'3rem',height:'10px'}}></div>
    { location.pathname!='/login' &&
    <div>
    <div className="container-fluid testimonial pt-5" style={{ overflow: 'hidden' }}>
      <div className="container">
        <div className="mx-auto text-center mb-5" style={{ maxWidth: '900px' }}>
          <h5 className="section-title px-3">Testimonial</h5>
          <h1 className="mb-0">Our Clients Say!!!</h1>
        </div>
      </div>
    </div>
    <Marquee>
    <div className="container-fluid testimonial pt-1 pb-5" style={{overflow:'hidden'}}>
      <div className="container px-0 pt-3 pb-5">
        <div className=" row testimonial-carousel owl-carousel" style={{Width:'200%'}}>
          <div className="testimonial-item text-center rounded pb-4 col-3">
            <div className="testimonial-comment bg-light rounded p-4">
              <p className="text-center testimonial-content mb-5">
              "The Autodial has transformed our outbound calling strategy. The ease of use and the efficiency it brings to our team is unmatched. We've seen a significant increase in lead conversions since we started using it!"
              <br /><br /><br /><br /><br /><br /><br />
                 </p>
            </div>
            <div className="testimonial-img p-1">
              <img src={testimonial1} className="img-fluid rounded-circle" alt="Image" />
            </div>
            <div style={{ marginTop: '-35px' }}>
              <h5 className="mb-0">Mike W.</h5>
              <p className="mb-0">Director of Operations at Greenline Solutions</p>
              <div className="d-flex justify-content-center">
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
              </div>
            </div>
          </div>
          <div className="testimonial-item text-center rounded pb-4 col-3">
            <div className="testimonial-comment bg-light rounded p-4">
              <p className="text-center testimonial-content mb-5">
              "As a small business owner, I needed a reliable and affordable solution to manage my customer calls. The Autodial has exceeded my expectations with its powerful features and intuitive interface. It's been a game-changer for my business!"                <span className='text-white'><br /><br /><br /><br /><br />.</span>
                </p>
            </div>
            <div className="testimonial-img p-1">
              <img src={testimonial2} className="img-fluid rounded-circle" alt="Image" />
            </div>
            <div style={{ marginTop: '-35px' }}>
              <h5 className="mb-0">Alan T.</h5>
              <p className="mb-0">Customer Success Lead at Infinity Corp.</p>
              <div className="d-flex justify-content-center">
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
              </div>
            </div>
          </div>
          <div className="testimonial-item text-center rounded pb-4 col-3">
            <div className="testimonial-comment bg-light rounded p-4">
              <p className="text-center testimonial-content mb-5">
              "The Autodial's automation features have saved our team countless hours. The seamless integration with our CRM ensures we never miss a follow-up. It's an essential tool for any business serious about scaling their outbound efforts."
              <br /><br /><br /><br /><br /><br /></p>
            </div>
            <div className="testimonial-img p-1">
              <img src={testimonial3} className="img-fluid rounded-circle" alt="Image" />
            </div>
            <div style={{ marginTop: '-35px' }}>
              <h5 className="mb-0">John D.</h5>
              <p className="mb-0">Sales Manager at Tech Solutions Inc.</p>
              <div className="d-flex justify-content-center">
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
              </div>
            </div>
          </div>
          <div className="testimonial-item text-center rounded pb-4 col-3">
            <div className="testimonial-comment bg-light rounded p-4">
              <p className="text-center testimonial-content mb-5" style={{fontSize:'13px'}}>
              "I was hesitant to switch to a new dialer, but The Autodial proved to be the best decision we made this year. The support team is incredibly responsive, and the software itself is robust and reliable. Our productivity has soared!"
              <br /><br /><br /><br /><br /></p>
              <span className='text-white'>.</span>
            </div>
            <div className="testimonial-img p-1">
              <img src={testimonial4} className="img-fluid rounded-circle" alt="Image" />
            </div>
            <div style={{ marginTop: '-35px' }}>
              <h5 className="mb-0">Sara L.</h5>
              <p className="mb-0">Founder of Bright Ideas Marketing</p>
              <div className="d-flex justify-content-center">
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Marquee>
    </div>
    }
    </>
  );
};

export default Testimonial;
