import React, { useEffect, useState } from 'react'
import contactUs from '../img/contactUs .png'
import { Link, useLocation } from 'react-router-dom';
import { homeScroll } from '../smoothScroll';
import autodial1 from '../img/Auto-Dialer1.jpg'
import bps from '../img/business-phone-system.webp';
import tnc from '../img/tnc.png';
import sla from '../img/sla.png';
import privacypolicy from '../img/privacy policy.png'
import Pricing from '../img/Pricing1.jpg';
import inbound from '../img/inbound-call-center.png'
import outbound from '../img/outbound.webp';
import black from '../img/autodial06.png';

import blob3 from '../img/blob5.svg';
import blob5 from '../img/graph3.png';
import blob6 from '../img/graph4.png'

export default function Carousel() {
  const location = useLocation();
  const [responsiveHead, setResponsiveHead] = useState('-10%');
  useEffect(() => {
    if (window.innerWidth < 844) {
      setResponsiveHead('10%')
    }
  });
  useEffect(() => {
    if (location.pathname === '/contact') {
      document.body.classList.add('setBodyBack')
    } else {
      // Optional: Reset the background when leaving the contact page
      document.body.classList.remove('setBodyBack')
    }
  }, [location.pathname]);
  return (
    <>
      {
        (location.pathname != '/singleProduct' && location.pathname != '/login')
        &&
        <div id='carousel' className="carousel-header" style={{ zIndex: '10' }}>
          <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
            <ol className="carousel-indicators">
              <li data-bs-target="#carouselId" data-bs-slide-to="0" className="active"></li>
              <li data-bs-target="#carouselId" data-bs-slide-to="1"></li>
            </ol>
            <div className="carousel-inner" role="listbox">

              <div className="carousel-item active">
                {location.pathname === '/contact' &&
                  <img src={contactUs} className="contact-img" alt="" />
                }
                {(location.pathname === '/') &&
                  <> <div className=' octagon'>
                    <img src={black}  alt="" />
                  </div>
                    <div className='center-svg'><img  src={blob3} alt="" /></div>
                    <div className='octagon1' >
                      <img src={blob6} alt="" />
                    </div>
                    <div className='octagon2'>
                      <img src={blob6} alt="" />
                    </div>
                    <div className='octagon3'>
                      <img src={blob5} alt="" />
                    </div>
                  </>}
                {location.pathname === '/pricing' &&
                  <> <div className=' octagon' >
                    <img src={Pricing} alt="" />
                  </div>
                    <div className='center-svg'><img  src={blob3} alt="" /></div>
                    <div className='octagon1' >
                      <img src={blob6} alt="" />
                    </div>
                    <div className='octagon2' >
                      <img src={blob6} alt="" />
                    </div>
                    <div className='octagon3' >
                      <img src={blob5} alt="" />
                    </div>
                  </>}

                {location.pathname === '/inbound-call-center' &&
                  <> <div className='octagon' >
                    <img src={inbound} alt="" />
                  </div>
                    <div className='center-svg'><img src={blob3} alt="" /></div>
                    <div className='octagon1' >
                      <img src={blob6} alt="" />
                    </div>
                    <div className='octagon2' >
                      <img src={blob6} alt="" />
                    </div>
                    <div className='octagon3' >
                      <img src={blob5} alt="" />
                    </div>
                  </>
                }
                {location.pathname === '/outbound-call-center' &&
                  <> <div className=' octagon'>
                    <img src={outbound} alt="" />
                  </div>
                    <div className='center-svg'><img src={blob3} alt="" /></div>
                    <div className='octagon1'>
                      <img src={blob6} alt="" />
                    </div>
                    <div className='octagon2'>
                      <img src={blob6} alt="" />
                    </div>
                    <div className='octagon3'>
                      <img src={blob5} alt="" />
                    </div>
                  </>
                }
                {location.pathname === '/bps' &&
                  <> <div className=' octagon'>
                    <img src={bps} alt="" />
                  </div>
                    <div className='center-svg'><img  src={blob3} alt="" /></div>
                    <div className='octagon1'>
                      <img src={blob6} alt="" />
                    </div>
                    <div className='octagon2'>
                      <img src={blob6} alt="" />
                    </div>
                    <div className='octagon3'>
                      <img src={blob5} alt="" />
                    </div>
                  </>
                }
                {location.pathname === '/SLA' &&
                  <img src={sla} className='policy-img' alt='/'></img>

                }
                {location.pathname === '/t&c' &&
                  <img src={tnc} className='policy-img' alt='/'></img>

                }
                {location.pathname === '/privacyPolicy' &&
                  <img src={privacypolicy} className='policy-img' alt='/'></img>

                }
                <div className="carousel-caption" style={{}}>
                  {location.pathname === '/' &&
                    <div className="p-3 home-head position-relative" style={{ maxWidth: "900px" }}>
                      <p className="text-primary  text1 text-uppercase fw-bold mb-md-4 mb-0" style={{ letterSpacing: ".3px" }}>
                        The Powerful All-In-One Platform
                      </p>

                      <p className="mb-md-5 mb-0 text2">
                      Inbound & outbound voice, text, email software, Auto dialers, predictive dialers, IVR, PBX. Sales automation,<br /> marketing automation, CRM.  Inbound, outbound, blended call center. Own the software or use our cloud service
                      </p>
                      <div onClick={() => { homeScroll('form') }} className='home-btn'>
                        <Link to="/contact" className="btn-hover-bg btn btn-primary  rounded text-white py-md-2 py-0 px-md-4 px-1"><span className=''>Watch Demo</span></Link>
                        <Link to="/pricing" className="btn-hover-bg btn btn-primary  rounded text-white py-md-2 py-0 px-md-4 px-1 ms-4"><span className=''>Pricing</span></Link>
                      </div>
                    </div>
                  }
                  {location.pathname === '/pricing' &&
                    <div className="p-3 home-head position-relative" style={{ maxWidth: "900px" }}>
                      <p className="text-primary text1 text-uppercase fw-bold mb-md-4 mb-0" style={{ letterSpacing: ".3px" }}>
                        Affordable Pricing Plans  Tailored<br /> to Your Needs
                      </p>

                      <p className="mb-md-5 mb-0 text2">
                        You will never feel you being charged extra !
                      </p>
                      <div onClick={() => { homeScroll('form') }} className='home-btn'>
                        <Link to="/contact" className="btn-hover-bg btn btn-primary  rounded text-white py-md-2 py-0 px-md-4 px-1"><span className=''>Watch Demo</span></Link>
                        <Link to="/pricing" className="btn-hover-bg btn btn-primary  rounded text-white py-md-2 py-0 px-md-4 px-1 ms-4"><span className=''>Pricing</span></Link>
                      </div>
                    </div>}
                  {location.pathname === '/inbound-call-center' &&
                    <div className="p-3 home-head position-relative" style={{ maxWidth: "900px" }}>
                      <p className="text-primary  text1 text-uppercase fw-bold mb-md-4 mb-0" style={{ letterSpacing: ".3px" }}>
                        Boost Satisfaction with Top Inbound <br />Call Center Solutions
                      </p>

                      <p className="mb-md-5 mb-0 text2">
                        Efficient, Reliable, and Customer-Centric Solutions to Elevate Your Service Experience                      </p>
                      <div onClick={() => { homeScroll('form') }} className='home-btn'>
                        <Link to="/contact" className="btn-hover-bg btn btn-primary  rounded text-white py-md-2 py-1 px-md-4 px-2"><span className=''>Watch Demo</span></Link>
                        <Link to="/pricing" className="btn-hover-bg btn btn-primary  rounded text-white py-md-2 py-1 px-md-4 px-2 ms-4"><span className=''>Pricing</span></Link>
                      </div>
                    </div>
                  }
                  {location.pathname === '/outbound-call-center' &&
                    <div className="p-3 home-head position-relative" style={{ maxWidth: "900px" }}>
                      <p className="text-primary text1 text-uppercase fw-bold mb-md-4 mb-0" style={{ letterSpacing: ".3px" }}>
                        Boost Sales with Our Advanced <br /> Outbound  Call Center Solutions!                      </p>

                      <p className="mb-md-5 mb-0 text2">
                        Drive Sales and Engagement with Targeted, High-Performance Outbound Calling                      </p>
                      <div onClick={() => { homeScroll('form') }} className='home-btn'>
                        <Link to="/contact" className="btn-hover-bg btn btn-primary  rounded text-white py-md-2 py-1 px-md-4 px-2"><span className=''>Watch Demo</span></Link>
                        <Link to="/pricing" className="btn-hover-bg btn btn-primary  rounded text-white py-md-2 py-1 px-md-4 px-2 ms-4"><span className=''>Pricing</span></Link>
                      </div>
                    </div>

                  }
                  {location.pathname === '/bps' &&
                    <div className="p-3 home-head position-relative" style={{ maxWidth: "900px" }}>
                      <p className="text-primary  text1 text-uppercase fw-bold mb-md-4 mb-0" style={{ letterSpacing: ".3px" }}>
                        Revolutionize Your Business <br /> Communication with The Autodial
                      </p>
                      <p className="mb-md-5 mb-0 text2">
                        Empowering Your Business with Cutting-Edge brPhone Solutions for Unmatched Connectivity.                      </p>
                      <div onClick={() => { homeScroll('form') }} className='home-btn'>
                        <Link to="/contact" className="btn-hover-bg btn btn-primary  rounded text-white py-md-2 py-1 px-md-4 px-2"><span className=''>Watch Demo</span></Link>
                        <Link to="/pricing" className="btn-hover-bg btn btn-primary  rounded text-white py-md-2 py-1 px-md-4 px-2 ms-4"><span className=''>Pricing</span></Link>

                      </div>
                    </div>

                  }

                  {location.pathname === '/contact' &&
                    <div className="p-3 contact-head position-relative" style={{ maxWidth: "900px" }}>
                      <p className="text-white text1 text-uppercase fs-1 fw-bold mb-md-4 mb-0" style={{ letterSpacing: ".3px" }}>
                        Contact Us!
                      </p>

                      {/* <div onClick={() => { homeScroll('form') }} className='contact-btn' style={{height:'4rem'}}><Link to="/contact" className="btn-hover-bg btn btn-primary  rounded-pill text-white py-md-3 py-1 px-md-5 px-2"><span className=''>Book your Free Consultation Now!</span></Link></div> */}
                    </div>}

                </div>
              </div>

            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
              {/* <span className="carousel-control-prev-icon btn" style={{ backgroundColor: "rgb(15, 195, 250)" }} aria-hidden="false"></span> */}
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
              {/* <span className="carousel-control-next-icon btn" style={{ backgroundColor: "rgb(15, 195, 250)" }} aria-hidden="false"></span> */}
              <span className="visually-hidden">Next</span>
            </button>

          </div>

          <div id="form" className='container-fluid opacity-0 position-absolute' style={{ height: '5px', top: '1205px' }}></div>
          <div id="plan" className='container-fluid opacity-0 bg-dark position-absolute' style={{ height: '8px', top: '4110px' }}></div>
          <div id="about" className='container-fluid opacity-0 bg-dark position-absolute' style={{ height: '8px', top: '0rem' }}></div>

        </div>

      }

    </>
  )
}
