// MainLayout.jsx
import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Marquee from 'react-fast-marquee';

import Testimonial from '../components/Testimonial';
import Subscribe from '../components/Subscribe';
import Carousel from '../components/Carousel';
import logo1 from '../img/Mlogo1.webp'
import logo2 from '../img/Mlogo2.png'
import logo3 from '../img/Mlogo3.webp'
import logo4 from '../img/Mlogo4.webp'
import logo5 from '../img/Mlogo5.png'
import logo6 from '../img/Mlogo6.png'
import logo7 from '../img/Mlogo7.avif'
import logo8 from '../img/Mlogo8.jpg'
import logo9 from '../img/Mlogo9.jpg'
import logo10 from '../img/Mlogo10.jpg'
import { Link } from 'react-router-dom';
const MainLayout = ({ children }) => (
    <>
        <Navbar />
        <Carousel />
        <Marquee style={{marginTop:'6rem',marginBottom:'2rem'}}>
            <div className='col-1' style={{}}><Link to="https://semya.in/"><img src={logo1} className='' style={{ height: '70px', top: '50px' }} alt="" /></Link></div>
            <div className='col-1 mx-4' style={{ marginRight: '8rem' }}><Link to="https://www.queenley.me/"><img src={logo2} className='' style={{ height: '100px', top: '40px' }} alt="" /></Link></div>
            <div className='col-1 mx-4'><Link to="https://theburgercompany.co/"><img src={logo3} className='' style={{ height: '100px', top: '40px' }} alt="" /></Link></div>
            <div className='col-1 mx-1' style={{}}><Link to="https://paanaroma.com/"><img src={logo4} className='' style={{ height: '80px', top: '50px', left: '-39%' }} alt="" /></Link></div>
            <div className='col-1 mx-4'><Link to="https://www.globaldesi.in"><img src={logo5} className='' style={{ height: '90px', top: '30px' }} alt="" /></Link></div>
            <div className='col-1 mx-4'><Link to="https://www.onlinecakencr.com/"><img src={logo6} className='' style={{ height: '70px', top: '60px' }} alt="" /></Link></div>
            <div className='col-1 mx-4' style={{}}><Link to="https://sunasa.in/"><img src={logo7} className='' style={{ top: '-10px' }} alt="" /></Link></div>
            <div className='col-1 mx-4'><Link to="https://vlccpersonalcare.com/"><img src={logo8} className='' style={{ width: '150px', top: '68px' }} alt="" /></Link></div>
            <div className='col-1 mx-4'><Link to="https://www.rangriti.com/"><img src={logo9} className='' style={{ width: '120px', top: '60px' }} alt="" /></Link></div>
            <div className='col-1 mx-4' style={{}}><Link to="https://basicslife.com/"><img src='https://basicslife.com/cdn/shop/files/Untitled-4-01_80x@2x.png?v=1711019726' className='' style={{ height: '100px', top: '40px', left: '15%' }} alt="" /></Link></div>
        </Marquee>
        <div className='Our-partners text-center text-uppercase text-primary'>Trusted by hundreds of Partners</div>
        {children}
        <Testimonial />
        <Subscribe />
        <Footer />
    </>
);

export default MainLayout;