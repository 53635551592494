import React from 'react'

export default function legalNotice() {
  return (
    <div className='privacy-policy'>
      <p className='head text-center mt-5'>JD Technical Solution SLA</p>
      <p className='content'>
        Choosing a hosting provider is never easy and it seems to be risky when your site is at stake. We know that the availability of your site is of utmost importance and entrusting your website to JD Technical Solution is something that we take seriously. That’s why we have built the hosting industry’s most aggressive Service Level Agreement (SLA) to cover the multiple components that keep your site up and running. <br /><br />
        JD Technical Solution SLA is a contract between you, the customer, and JD Technical Solution. It defines the terms of our responsibility and the money back guaranty if our responsibilities are not met. We want our customers to feel at ease with their decision to move their site to JD Technical Solution, and knowing that JD Technical Solution takes your site’s uptime as seriously as you do is imperative
      </p>
      <p className='head2'>UPTIME NETWORK</p>
      <p className='content'>
        <span className='text-info'>We guarantee less than 2 hours down time per month. </span>We have a money back policy if the downtime exceeded out guaranteed time.
      </p>
      <div className='table'>
        <table className='table-content'>
          <tr>
            <th className=' fw-bold text-info'>HOURS OF DOWN TIME</th>
            <th className='fw-bold text-info'>REFUND (% OF TOTAL AMOUNT)</th>
          </tr>
          <tr>
            <th>2 Hrs</th>
            <th>5%</th>
          </tr>
          <tr>
            <th>4 Hrs</th>
            <th>10%</th>
          </tr>
          <tr>
            <th>6 Hrs</th>
            <th>15%</th>
          </tr>
          <tr>
            <th>8 Hrs</th>
            <th>20%</th>
          </tr>
          <tr>
            <th>10 Hrs</th>
            <th>25%</th>
          </tr>
          <tr>
            <th>12 Hrs</th>
            <th>30%</th>
          </tr>
        </table>
      </div>
      <p className='head2'>Exception</p>
      <p className='content'>
        1.	Interruption due to scheduled maintenance, alteration, or implementation, where the Service Provider provides at least seven days prior notice and to the Customer and also interruption due to Emergency Maintenance; The usual scheduled maintenance time is the early hours of the morning i.e., between 1am to 6am. The usual maintenance time would not be more than two hours. <br />
        2.	Hardware failure. <br />
        3.	Failure of the Customer links, internet connectivity or end user software, access circuits, local loopor any network not owned or managed by JD Technical Solution. <br />
        4.	Negligence or other conduct of Customer or its authorized persons, including a failure normal function resulting from applications or services provided by Customer or its authorized persons. <br />
        5.	A shut down due to circumstances reasonably believed by JD Technical Solution to be a significant threat to the normal operation of the Services, JD Technical Solution’s facility, or access to or integrity of Customer data (e.g., hacker or virus attack). <br />
        6.	Failure or malfunction of any equipment or services not provided by JD Technical Solution. <br />
        7.	Any abuse or fraud failure to comply with the Acceptable User Policy on the part of Customer and its authorized persons. <br />
        8.	Any problems outside the Service Provider Facility Network. <br />
        9.	Any interruptions, delays or failures caused by Customer or Customer’s employees, agents, or subcontractors, such as, the following:
        – Inaccurate configuration. <br />
        – Non-compliant use of any software installed on the server. <br />
        10.	Customer initiated server over-utilization. <br />
        11.	Any problems related to the attacks on the machine such as hacking, attacks, and exploits. <br />
        12.	Any specific services not part of Services. <br />
        13.	Force Majeure event. <br />
      </p>
      <p className='head2'>ESCALATION MATRIX</p>
      <p className='content'>
        <span className='text-info fw-bold'>We have the fastest escalation matrix where you can </span>contact the CTO in 12 hours if your issue is not resolved. <br /> <br />
        </p>
        <p className='head2'>TICKET RESPONSE</p>
        <p className='content'>
        1.	24x7x365 Hours support Support desk team will provide the Ticket # <br />
        2.	For your call. This Ticket # to be quoted for further escalations if required. <br />
        3.	Hours Call On Call +91 9971979794 , 011-414-98960(extension no. 2)
        </p>
      <p className='head2'>Hardware</p>
      <p className='content'>
        <span className='text-info fw-bold'>We guarantee the functioning of all server hardware components and will replace any failed component at no cost. “Hardware” means the processor(s), RAM, hard </span>disk(s), motherboard, NIC card and other related hardware included with the server. Hardware replacement will begin once we identify the cause of the problem. Hardware replacement is guaranteed to be complete within one hour of problem identification. <br /><br />
        <span className='text-info fw-bold'>JD Technical Solution Guarantee: We will credit your account 5% of the monthly fee per </span>additional hour of downtime, up to 100% of your monthly fee for the affected
      </p>
    </div>
  )
}
