import React, { useState } from 'react';
import logo from '../img/theautodialLogo.png';
import '../assets/css/googleForm.css';

export default function GoogleForm() {
    const [height,setHeight]= useState(window.innerHeight);
    return (
        <div>
            <div className='form-logo text-center'>
                <img src={logo} className='w-50' alt="" />
            </div>
                <iframe className='googleForm'
                    src="https://docs.google.com/forms/d/e/1FAIpQLScAXRULtQmeXDfmRvBG5ncNfl0CyKyxZTUpw_FVS3N5Vzx66w/viewform?embedded=true"
                    width="100%"
                    height={height}
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    title='autodialResponseForm'
                >
                    Loading…
                </iframe>
            </div>
        
    );
}
