import { useState } from "react";
import LogContext from "./LogContext";
import React from 'react'

const LogState=(props)=>{
    const[currentNavigation,setCurrentNavigation]=useState('/');
    const [currencyRatio, setCurrencyRatio]= useState();
    const [currencyType, setCurrencyType]= useState();

    const updateCurrency=(type, ratio)=>{
        setCurrencyType(type);
        setCurrencyRatio(ratio);
    }
  return (
    <LogContext.Provider value={{updateCurrency,currencyRatio,currencyType}}>
            {props.children}
    </LogContext.Provider>
  )
}
export default LogState;