import React from 'react';
import '../../../assets/css/predictive.css';
import headImg from '../../../img/solutions-outbound.svg';
import predictiveDialer from '../../../img/how-do-predictive-dialers-work.jpeg';
import howWork from '../../../img/how-predictive-work.jpg';
import { useNavigate } from 'react-router-dom';
import { homeScroll } from '../../../smoothScroll';
export default function IBCC() {
  const navigate= useNavigate();
  return (
    <>
      
      <div className='predictiveDialing'>
        <div className='what row'>
          <div className='col-6 px-md-5 ps-4 pe-0'>
            <p className='head'>
              What Is A Predictive Dialer?
            </p>
            {window.innerWidth > 576 && <p>Predictive dialers are a type of auto-dialing software used in outbound sales to maximize agent productivity. The name is derived from the system’s key ability to <span className='fw-bold'>predict agent availability</span>  and dial the next contact in a queue, even before the previous call ends. </p>}
            {window.innerWidth > 576 && <p>Originally designed by Douglas Samuelson in the late 1980s, the technology has since moved from analog to digital solutions and become a common feature in VoIP call center systems, sometimes also known as <span className='fw-bold'>a hosted predictive dialer</span> .</p>}
          </div>
          <div className='col-6'>
            <img src={predictiveDialer} className='w-100' alt="" />
          </div>
          {window.innerWidth <= 576 &&
            <>
              <div className='my-3 px-4'>
                <p>Predictive dialers are a type of auto-dialing software used in outbound sales to maximize agent productivity. The name is derived from the system’s key ability to <span className='fw-bold'>predict agent availability</span>  and dial the next contact in a queue, even before the previous call ends. </p>
                <p>Originally designed by Douglas Samuelson in the late 1980s, the technology has since moved from analog to digital solutions and become a common feature in VoIP call center systems, sometimes also known as <span className='fw-bold'>a hosted predictive dialer</span> .</p>
              </div>
            </>
          }

          <div className='col-6'>
            <img src={headImg} className='w-100' alt="" />
          </div>
          <div className='col-6'>
            <p className='head'>
              Predictive Dialer:
              Guide & Sales Dialer Alternatives For 2024
            </p>
            {window.innerWidth > 576 && <>
              <p className='content'>Optimize your outbound call campaigns to reach more prospects faster while keeping downtime and call costs to a minimum.</p>
              <p className='content'>Find out what a predictive dialer is, what it does, and how it works, and discover the best The Autodial sales dialer alternatives to maximize your performance in 2024. </p>
              <button className='btn btn-info px-5 py-3 fs-5 my-3 text-white fw-bold' onClick={()=>{navigate('/contact');homeScroll('about')}}>Contact Sale</button>
            </>}
          </div>
        </div>
        {window.innerWidth <= 576 && <div className='my-3 px-4'>
          <p className='content'>Optimize your outbound call campaigns to reach more prospects faster while keeping downtime and call costs to a minimum.</p>
          <p className='content'>Find out what a predictive dialer is, what it does, and how it works, and discover the best The Autodial sales dialer alternatives to maximize your performance in 2024. </p>
          <button className='btn btn-info px-5 py-3 fs-5 my-3 text-white fw-bold' onClick={()=>{navigate('/contact');homeScroll('about')}}>Contact Sale</button>
        </div>}

        <div className='p-md-5 p-3 mt-md-5 mt-2'>
          <p className='text-center head'>How Much Does A Predictive Dialer Cost?</p>
          <p className='content'>
            The cost of a predictive dialer largely depends on the provider, the number of users, deployment, and additional features. Due to that, it’s difficult to give a specific number, but you can expect to pay anywhere between a few hundred to a few thousand dollars. On top of that, standalone dialers are fairly rare, so you may have to choose from a limited number of providers and end up paying more than you feel comfortable with.
          </p>
          <p className='content fw-bold'>So, what should you do?</p>
        </div>
        <div className='p-md-5 p-3 mt-md-5 mt-2'>
          <p className='text-center head'>
            The Cost-Effective Alternative: Hosted Sales Dialer Software
          </p>
          <p className='content'>
            As mentioned above, predictive and other sales dialers are often bundled with other powerful automation features in VoIP and next-gen business calling solutions, such as The Autodial. This allows you to get the most bang for your buck, as you can access 100+ features, including both a Power and Smart Dialer, and 35+ market-leading integrations for just $25 per user/month.
          </p>
          <p className='content'>
            Furthermore, The Autodial’s hosted sales dialer software is quick to deploy (1-7 days) thanks to its no-code architecture, easy to use, and fully remote-friendly. With this in mind, the question is…
          </p>
        </div>
        <div className='how-works p-md-5 p-3 mt-md-5 mt-2'>
          <p className='head text-center'>How Does A Predictive Dialer Work? </p>
          <p className='content'>Predictive dialers initiate multiple calls from a list/queue of contacts, canceling the ones that don’t get a response in a predefined time frame and routing the ones that do to available agents, which allows businesses to maximize agent productivity at the call center level. </p>
          <p className='content'>Meanwhile, it gives agents the time to properly attend each caller without having to worry about repetitive tasks, such as manual dialing and after-call-work (ACW), boosting customer satisfaction and conversion rates in the process.</p>
          <div className='text-center'>
            <img src={howWork} style={{ width: '70%' }} alt="" />
          </div>
        </div>
        <div className='difference-dialing p-md-5 p-3 mt-md-5 mt-2'>
          <p className='head text-center my-4'>What Is The Difference Between A Predictive Dialer, Auto Dialer, Power Dialer, And Smart Dialer? </p>
          <table className=''>
            <tr>
              <td className='fw-bold'>Comparison</td>
              <td ><div className='shadow rounded fw-bold'>Auto Dialer</div></td>
              <td ><div className='shadow rounded fw-bold'>Predictive Dialer</div></td>
              <td ><div className='shadow rounded fw-bold'>Power Dialer</div></td>
              <td ><div className='shadow rounded fw-bold'>Smart Dialer</div></td>
            </tr>
            <tr>
              <td className='fw-bold fs-5'>Overview</td>
              <td >
                <div className='text-center'>
                  <p>
                    Auto Dialers are an umbrella term for hardware and software systems that allow users to make calls without needing to manually dial phone numbers
                  </p>
                  <p>Predictive, power, and smart dialers are all auto dialers.</p>
                </div>
              </td>
              <td >
                <div className='text-center'>
                  <p>
                    Predictive dialers are systems that can anticipate call center capacity and initiate multiple calls simultaneously to effectively utilize all available resources.
                  </p>
                  <p>Sometimes, it can boost successful calls by {'<'}400%.</p>
                </div>
              </td>
              <td >
                <div className='text-center'>
                  <p>
                    Power dialers make one call at a time for each available agent. They don’t require complex algorithms and are perfect for more controlled outbound campaigns.
                  </p>
                  <p>They can help you reach 3x more customers in a day.</p>
                </div>
              </td>
              <td >
                <div className='text-center'>
                  <p>
                    Smart dialers go beyond the standard capabilities of a predictive dialer and allow you to make customized adaptive call queues with a single click from your browser or CRM.
                  </p>
                  <p>They can lower call times by {'<'}50%.</p>
                </div>
              </td>
            </tr>
            <tr>
              <td className='fw-bold fs-5'>Pros</td>
              <td ><div className='text-center'>Easy to use, minimal requirements, improves number of calls made.</div></td>
              <td ><div className='text-center'>Capacity prediction, non-human number filtering, performance tracking.</div></td>
              <td ><div className='text-center'>Pacing management, workflow automation, lower call abandonment</div></td>
              <td ><div className='text-center'>Intelligent call pacing, adaptive algorithms, ensured compliance.</div></td>
            </tr>
            <tr>
              <td className='fw-bold fs-5'>Cons</td>
              <td ><div className='text-center'>No targeting, no additional workflow automation, can lead to burnout.</div></td>
              <td ><div className='text-center'>May overestimate capacity and cause call abandonment and burnout.</div></td>
              <td ><div className='text-center'>Slightly more downtime, smaller increase in daily calls, limited filtering.</div></td>
              <td ><div className='text-center'>Potentially more expensive, may require additional agent training.</div></td>
            </tr>
            <tr>
              <td className='fw-bold fs-5'>Best For</td>
              <td ><div className='text-center'>Low-cost, high-volume call campaigns.</div></td>
              <td ><div className='text-center'>High-volume call campaigns with limited resources.</div></td>
              <td ><div className='text-center'>High-volume call campaigns with a personalized approach.</div></td>
              <td ><div className='text-center'>High-value, medium-volume call campaigns optimized for CSAT.</div></td>
            </tr>
          </table>
        </div>
        <div className='p-md-5 p-3 mt-md-5 mt-2'>
          <p className='text-center head'>
            Should You Use A Predictive Dialer?
          </p>
          <p className='content'>
            If it makes sense for your business and nothing else will do, then absolutely! However, if you want to be as efficient as possible and care more about conversions than just the number of daily calls, you may want to consider trying The Autodial’s Power Dialer.  It offers the best of both worlds. But you don’t have to take our word for it. You can see it in action yourself.
          </p>
          <button className='btn btn-info px-5 py-3 fs-5 fw-bold text-light mt-5' onClick={()=>{navigate('/contact');homeScroll('about')}}>
            Contact Sales
          </button>
        </div>

        <div className='call-for-sale rounded'>
          <p className='head text-center text-white'>
            Unite Agents, Customers, and Software Seamlessly in the Cloud
          </p>

          <p className='text-center mt-5 text-white'>Learn how The Autodial can give you unmatched control over your customers' experience and begin meeting their expectations today.</p>
          <div className='text-center'>
            <button className='btn btn-light my-5 px-5 py-3 fs-5 text-primary' onClick={()=>{navigate('/contact');homeScroll('about')}}>Schedule Demo</button>
          </div>
        </div>
        <div className='faq my-5'>
          <p className='head text-primary fs-2 fw-bold text-center mb-5'>FAQ's</p>
          <div className="accordion shadow" id="accordionPanelsStayOpenExample">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                  Are Predictive Dialers Illegal?
                </button>
              </h2>
              <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                <div className="accordion-body" >
                  <p>Predictive dialers are not inherently illegal, but they are subject to several laws and regulations that limit their use in order to protect consumers from unwanted or abusive telemarketing. This is the case in the <span className='fw-bold'>US, Canada, UK, EU, Australia</span> and others.</p>
                  <p className='fw-bold'>Regulations include:</p>
                  <ul className='fw-bold'>
                    <li>Do-Not Call Regulations</li>
                    <li>Consent Requirements</li>
                    <li>Consent Requirements</li>
                    <li>Time-Of-Day Restrictions</li>
                    <li>TCPA Compliance</li>
                  </ul>
                  <p>The Autodial is compliant with all relevant laws and regulations, including GDPR, STIR/SHAKEN, and MAN compliant. You can learn more about our security standards here.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                  How Does A Predictive Dialer Work With A Cell Phone?
                </button>
              </h2>
              <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
                <div className="accordion-body">
                  Analog predictive dialers don’t work with cell phones because they have to be wired via landline to function. However, hosted sales dialer solutions can be easily downloaded from Google Play and the App Store and used on any smartphone via an app like The Autodial Go.              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
