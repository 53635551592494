import React, { useContext, useEffect, useState } from 'react'
import '../assets/css/pricing.css';
import starter from '../img/pricing-starter.svg';
import essential from '../img/pricing-essential.svg';
import expert from '../img/pricing-expert.svg';
import custom from '../img/pricing-custom.svg';
import iconCircleBlue from '../img/icon-circle-blue.svg';
import iconCircleCross from '../img/icon-circle-cross.svg'
import LogContext from '../context/LogContext';
import { useNavigate } from 'react-router-dom';
import { homeScroll } from '../smoothScroll';
export default function Pricing() {
    const myContext = useContext(LogContext);
    const navigate= useNavigate();
    const [currencyRatio, setCurrencyRatio] = useState();
    const [currencyType, setCurrencyType] = useState();
    const [planType, setPlanType] = useState('Annually');
    const [plansPricing, setPlansPricing] = useState([]);
    const setConverterRatio = (type) => {
        console.log(type)
        const api = "https://api.exchangerate-api.com/v4/latest/USD";
        function getResults() {
            fetch(`${api}`)
                .then(currency => {
                    return currency.json();
                }).then(currency => {
                    let fromRate = currency.rates['INR'];
                    let toRate = currency.rates[type];
                    setCurrencyRatio(toRate / fromRate);
                    setCurrencyType(type);
                });
        }
        getResults();
    }

    useEffect(() => {
        setCurrencyRatio(myContext.currencyRatio);
        setCurrencyType(myContext.currencyType);
        console.log(myContext.currencyRatio, myContext.currencyType);

    }, [myContext]);
    useEffect(() => {
        if (planType === "Annually") {
            setPlansPricing([2099, 2499, 3999]);
            const btn1 = document.getElementById('annuallyBtn');
            const btn2 = document.getElementById('monthlyBtn');
            btn1.classList.remove('btn-light');
            btn1.classList.add('btn-primary');
            btn2.classList.remove('btn-primary');
            btn2.classList.add('btn-light');

        }
        else {
            setPlansPricing([2799, 3199, 5499]);
            const btn2 = document.getElementById('annuallyBtn');
            const btn1 = document.getElementById('monthlyBtn');
            btn1.classList.remove('btn-light');
            btn1.classList.add('btn-primary');
            btn2.classList.remove('btn-primary');
            btn2.classList.add('btn-light');

        }
    }, [planType]);
    useEffect(() => {
        console.log(planType)
    })
    return (
        <>
            <div className='pricing my-5 text-primary'>
                <div className='pb-5 pt-3'>
                    <p className='head2 px-md-5 px-2 text-center'>Flexible Pricing plans to scale with you</p>
                    <p className='px-md-5 px-2 text-center checks'>
                        <span className='mx-md-3 mx-1'>
                            <i className="bi bi-check"></i>
                            Market leading Call Quality
                        </span>
                        <span className='mx-4'>
                            <i className="bi bi-check"></i>
                            Monthly or Annual Billing
                        </span>
                        <span className='mx-4'>
                            <i className="bi bi-check"></i>
                            Access to 160+ International numbers
                        </span>
                    </p>
                </div>
                <div className='plans py-2'>
                    <div className='plans-head px-md-3 px-0 py-3 row rounded'>
                        <div className='col-4 p-1'>
                            <div className='rounded annual-monthly p-1 text-center d-flex' style={{ background: "white" }} >
                                <p id='annuallyBtn' className='btn btn-light px-md-4 px-1 me-md-3 me-0 w-50 my-0' onClick={() => setPlanType('Annually')}>Annually <span className='rounded bg-secondary-50'>-25%</span></p>
                                <p id='monthlyBtn' className='btn btn-light px-md-5 px-1 ms-md-3 ms-1 w-50 my-0' onClick={() => setPlanType('Monthly')}>Monthly</p>
                            </div>
                        </div>
                        <div className='col-4 px-2 py-1 '>
                            <select className='rounded text-center w-100 select-options px-3' style={{ background: "#f0f0f0" }} onClick={(e) => { if (e.target.value === 'Spain' || e.target.value === 'United Kingdom' || e.target.value === 'Belgium') { setConverterRatio('EUR') } else if(e.target.value === 'USA' || e.target.value === 'Australia' ||e.target.value === 'New Zealand' || e.target.value === 'Canada' ) { setConverterRatio('USD') } else{setConverterRatio('INR') } }} >
                                <option value="India">India</option>
                                <option value="Australia">Australia</option>
                                <option value="Belgium">Belgium</option>
                                <option value="Canada">Canada</option>
                                <option value="New Zealand">New Zealand</option>
                                <option value="Spain">Spain</option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="USA">USA</option>
                            </select>

                        </div>
                        <div className='col-4 px-2 py-1'>
                            <select className='rounded text-center w-100 select-options px-3' style={{ background: "#f0f0f0" }} onChange={(e) => setConverterRatio(e.target.value)} >
                                <option value="INR">Indian Rupee (₹)</option>
                                <option value="USD">US Dollar ($)</option>
                                <option value="EUR">Euro (€)</option>
                            </select>
                        </div>
                    </div>
                    <div className='plans-cards mt-5 px-2'>
                        <div className="row row-cols-1 row-cols-md-4 g-4">
                            <div className="col">
                                <div className="card h-100">

                                    <div className="card-body">
                                        <h4 className="card-title fw-bold" style={{ color: "black" }}>
                                            <img src={starter} alt="" /> <span className='mx-3'>Starter</span>
                                        </h4>
                                        <p className='card-head'><span className='head'>{currencyType === 'INR' ? '₹' : (currencyType==='USD'? '$':'€')}{(plansPricing[0] * currencyRatio).toFixed(0)}</span><span className='fw-bold text-secondary'> / month</span></p>
                                        <p>Per user/month. Billed {planType}.</p>
                                        <hr />
                                        <div className="card-text">
                                            <p className='card-text-head'> What's Included?</p>
                                            <ul className='card-text-li' style={{ listStyle: 'none' }}>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Unlimited inbound & intracompany calls</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>International numbers (160+ countries)</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Softphone for desktop, iOS, Android</p>
                                                </li>
                                                {/* <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Call recording</p>
                                                </li> */}
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Click to call, Automated call distribution & Unlimited call queuing</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Live call monitoring & wallboards</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Detailed Call Reports</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Email & Chat support</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='px-4 py-3'><button className='btn btn-secondary align-bottom w-100 py-3' onClick={()=>{navigate('/contact');homeScroll('about')}}>Contact For Sale</button></div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card h-100">

                                    <div className="card-body">
                                        <h5 className="card-title fw-bold" style={{ color: 'black' }}>
                                            <img src={essential} alt="" /> <span className='mx-3'>Essential</span>
                                        </h5>
                                        <p className='card-head'><span className='head'>{currencyType === 'INR' ? '₹' : (currencyType==='USD'? '$':'€')}{(plansPricing[1] * currencyRatio).toFixed(0)}</span><span className='fw-bold text-secondary'> / month</span></p>
                                        <p>Per user/month. Billed {planType}.</p>
                                        <hr />
                                        <div className="card-text">
                                            <p className='card-text-head'> What's Included?</p>
                                            <ul className='card-text-li' style={{ listStyle: 'none' }}>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>All Starter features, plus…</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>24/7 live human support</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Advanced real-time analytics with unlimited history</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Call Recording</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Integrations to other systems & API</p>
                                                </li>
                                                {/* <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>IVR, Skill-based routing, AI Smart queueing</p>
                                                </li> */}
                                                {/* <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Global SMS / MMS messages</p>
                                                </li> */}
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Unlimited concurrent calls</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Workflow Automation</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='px-4 py-3'><button className='btn btn-warning align-bottom w-100 py-3' onClick={()=>{navigate('/contact');homeScroll('about')}}>Contact For Sale</button></div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card h-100">

                                    <div className="card-body">
                                        <h5 className="card-title fw-bold" style={{ color: 'black' }}>
                                            <img src={expert} alt="" /> <span className='mx-3'>Expert</span>
                                        </h5>
                                        <p className='card-head'><span className='head'>{currencyType === 'INR' ? '₹' : (currencyType==='USD'? '$':'€')}{(plansPricing[2] * currencyRatio).toFixed(0)}</span><span className='fw-bold text-secondary'> / month</span></p>
                                        <p>Per user/month. Billed {planType}.</p>
                                        <hr />
                                        <div className="card-text">
                                            <p className='card-text-head'> What's Included?</p>
                                            <ul className='card-text-li' style={{ listStyle: 'none' }}>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>All Essential features, plus…</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>24/7 live agent support (incl. phone)</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Sticky Agent</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>IVR, Skill-based routing, AI Smart queueing</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Advanced support features</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Advanced sales features (Power dialer, Smart dialer)</p>
                                                </li>
                                    
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Mandatory call tagging</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>WhatsApp</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Single Sign-On (SSO)</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='px-4 py-3'><button className='btn btn-primary align-bottom w-100 py-3' onClick={()=>{navigate('/contact');homeScroll('about')}}>Contact For Sale</button></div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <h5 className="card-title fw-bold" style={{ color: 'black' }}>
                                            <img src={expert} alt="" /> <span className='mx-3'>Custom</span>
                                        </h5>
                                        <p className='card-head1 mt-4'><span>Contact us </span><br /><span>for tailor-made offer</span></p>
                                        <hr />
                                        <div className="card-text">
                                            <p className='card-text-head'> What's Included?</p>
                                            <ul className='card-text-li' style={{ listStyle: 'none' }}>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>All Expert features, plus…</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Unlimited worldwide call packages</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Custom onboarding & Priority support</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Custom reporting</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Enterprise-level security</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>Developer support</p>
                                                </li>
                                                <li className='d-flex'>
                                                    <p className='me-2'><span style={{ fontSize: "1.5rem" }}><i className="bi bi-check" style={{ position: 'relative', top: "-.4rem" }}></i></span></p>
                                                    <p style={{ fontSize: '.85rem', fontWeight: "500" }}>SLA</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='px-4 py-3'><button className='btn btn-dark align-bottom w-100 py-3' onClick={()=>{navigate('/contact');homeScroll('about')}}>Contact For Sale</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='detailed-pricing my-5 py-5'>
                    <p className='head px-5 text-center mb-5'>Detailed pricing overview</p>
                    <div className='pricing-table rounded'>
                        <table className='w-100 rounded shadow'>
                            <tr>
                                <td className='text-secondary fs-6 fw-bold'>Detailed pricing overview</td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={starter} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Starter</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={essential} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Essential</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={expert} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Expert</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={custom} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Custom</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Price per user per month (billed annually)</td>
                                <td className='text-dark fs-6 fw-bold'>
                                {currencyType === 'INR' ? '₹' : (currencyType==='USD'? '$':'€')}{(2099 * currencyRatio).toFixed(0)}
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                {currencyType === 'INR' ? '₹' : (currencyType==='USD'? '$':'€')}{(2699 * currencyRatio).toFixed(0)}
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                {currencyType === 'INR' ? '₹' : (currencyType==='USD'? '$':'€')}{(4199 * currencyRatio).toFixed(0)}
                                </td>
                                <td>
                                    <button className='rounded-pill bg-light border border-primary px-3 py-2 text-primary' onClick={()=>{navigate('/contact');homeScroll('about')}}>Contact Us</button>
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Minimum number of users</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    1
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    1
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    3
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    10
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Maximum number of users</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Unlimited
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Unlimited
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Unlimited
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Unlimited
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Maximum number of groups</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Unlimited
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Unlimited
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Unlimited
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Unlimited
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Phone number included in the plan</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    1 standard local number free
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    1 standard local number free
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    1 standard local number free
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    1 standard local number free
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Price of each additional number per month</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    from {currencyType === 'INR' ? '₹' : (currencyType==='USD'? '$':'€')}{(500 * currencyRatio).toFixed(0)}
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    from {currencyType === 'INR' ? '₹' : (currencyType==='USD'? '$':'€')}{(500 * currencyRatio).toFixed(0)}
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    from {currencyType === 'INR' ? '₹' : (currencyType==='USD'? '$':'€')}{(500 * currencyRatio).toFixed(0)}
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    from {currencyType === 'INR' ? '₹' : (currencyType==='USD'? '$':'€')}{(500 * currencyRatio).toFixed(0)}
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Maximum number of callers in queue</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Unlimited
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Unlimited
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Unlimited
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Unlimited
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Unlimited inbound & intracompany calls
                                    (toll-free excluded)</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Outbound calls – international</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Contact us for rates and call packages
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Contact us for rates and call packages
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Contact us for rates and call packages
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Contact us for rates and call packages
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Price of SMS/MMS messaging</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    per message
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    per message
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <button className='rounded-pill bg-light border border-primary px-3 py-2 text-primary'onClick={()=>{navigate('/contact');homeScroll('about')}}>Contact Us</button>
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Storage for recordings</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    1 month
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Unlimited
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Unlimited
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Unlimited
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Analytics data</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    unlimited
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Unlimited
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Unlimited
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    Unlimited
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className='pricing-comparision'>
                    <p className='head px-5 text-center mt-5 text-center'>Package comparison</p>
                    <p className='text-secondary fs-5 text-center'>Compare our different options and find the right one for you.</p>
                    <div className='pricing-table rounded my-5'>
                        <table className='w-100 rounded shadow'>
                            <tr>
                                <td className='text-secondary fs-6 fw-bold'>Voice features</td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={starter} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Starter</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={essential} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Essential</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={expert} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Expert</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={custom} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Custom</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Call queuing</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Call recording</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Internal calls / Extensions</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Voicemail</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Mobile app</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>International numbers</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Toll free numbers</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Short numbers</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Number porting</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Fax to email</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Business hours</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Personalized greetings & music</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Internal phone number tags</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>SMS / MMS messages</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Conference calls</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Call masking</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div className='pricing-table my-5 rounded'>
                        <table className='w-100 rounded shadow'>
                            <tr>
                                <td className='text-secondary fs-6 fw-bold'>Intelligent call routing</td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={starter} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Starter</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={essential} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Essential</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={expert} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Expert</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={custom} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Custom</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Automated Call Distribution (ACD)</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Call Flow Designer</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Go-To CFD Feature</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Caller-based routing</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Preferred agent</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Automatic outbound caller ID</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Ring groups</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Automated call redirection</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Interactive Voice Response (IVR) menu</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>VIP Queues</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Skill-based routing</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Smart routing</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Internal phone number tags</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>SMS / MMS messages</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Conference calls</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Call masking</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <button className='rounded-pill bg-light border border-primary px-3 py-2 text-primary'onClick={()=>{navigate('/contact');homeScroll('about')}}>Contact Us</button>
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <button className='rounded-pill bg-light border border-primary px-3 py-2 text-primary' onClick={()=>{navigate('/contact');homeScroll('about')}}>Contact Us</button>
                                </td>
                                <td>
                                    <button className='rounded-pill bg-light border border-primary px-3 py-2 text-primary' onClick={()=>{navigate('/contact');homeScroll('about')}}>Contact Us</button>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div className='pricing-table my-5 rounded'>
                        <table className='w-100 rounded shadow'>
                            <tr>
                                <td className='text-secondary fs-6 fw-bold'>Productivity</td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={starter} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Starter</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={essential} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Essential</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={expert} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Expert</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={custom} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Custom</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Ring on speakers</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Voicemail by email</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Click to Call</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Desktop notifications</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Callback</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Smart detection of resolved missed calls</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Call tagging</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Call notes</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Mandatory tagging</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Comment on calls</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Custom fields</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Contact tagging</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Real-time customer card</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>SMS / MMS messages</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>After-call work</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Blacklist</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Transfer</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Speech to text</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <button className='rounded-pill bg-light border border-primary px-3 py-2 text-primary' onClick={()=>{navigate('/contact');homeScroll('about')}}>Contact Us</button>
                                </td>
                                <td>
                                    <button className='rounded-pill bg-light border border-primary px-3 py-2 text-primary' onClick={()=>{navigate('/contact');homeScroll('about')}}>Contact Us</button>
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Smart dialer</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Power dialer</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className='pricing-table my-5 rounded'>
                        <table className='w-100 rounded shadow'>
                            <tr>
                                <td className='text-secondary fs-6 fw-bold'>Integrations</td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={starter} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Starter</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={essential} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Essential</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={expert} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Expert</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={custom} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Custom</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Contact importer</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Contact history</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Shared contacts</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Contact details before picking up the call</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Create tasks directly from The Autodial</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>API</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Workflow Automation</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Access to developer API support</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Integrations</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Salesforce Service Cloud integration</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Salesforce Sales Cloud integration</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>WhatsApp Inbound Messages</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>WhatsApp Outbound Messages</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className='pricing-table my-5 rounded'>
                        <table className='w-100 rounded shadow'>
                            <tr>
                                <td className='text-secondary fs-6 fw-bold'>Statistics and monitoring</td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={starter} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Starter</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={essential} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Essential</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={expert} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Expert</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={custom} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Custom</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Call statistics</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Advanced analytics with unlimited history</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Call monitoring
                                    (Call barging, Call whispering)</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Agent reporting</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>CSAT Score</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    AI add-on
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    AI add-on
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    AI add-on
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    AI add-on
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Wallboard</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Custom reporting</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className='pricing-table my-5 rounded'>
                        <table className='w-100 rounded shadow'>
                            <tr>
                                <td className='text-secondary fs-6 fw-bold'>Utilities</td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={starter} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Starter</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={essential} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Essential</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={expert} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Expert</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={custom} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Custom</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Security</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Online user management</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Unlimited concurrent calls</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Responsive web admin</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Single Sign-On (SSO)</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className='pricing-table my-5 rounded'>
                        <table className='w-100 rounded shadow'>
                            <tr>
                                <td className='text-secondary fs-6 fw-bold'>Customer support</td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={starter} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Starter</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={essential} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Essential</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={expert} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Expert</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='rounded shadow bg-white p-2 text-start'>
                                        <img src={custom} alt="/" /><span className='fs-6 fw-bold mx-3 text-dark'>Custom</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Help center access</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Email support</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Phone support</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Onboarding coach</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>Dedicated account manager</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                            <tr>
                                <td className='text-dark fs-6 fw-bold'>SLA</td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td className='text-dark fs-6 fw-bold'>
                                    <img src={iconCircleCross} alt="/" />
                                </td>
                                <td>
                                    <img src={iconCircleBlue} alt="/" />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className='fw-bold text-center warnings' style={{ color: 'black', fontSize: ".75rem", paddingInline: "20rem" }}> Please note that our prices are VAT exclusive. VAT will be billed to EU customers in line with the applicable rates of their member state unless a valid VAT number is provided. For more information please visit geographic phone number pricing and country coverage for phone numbers.</div>
                </div>
                <div className='story' style={{}}>
                    <p className='head text-warning'>More than 4,000 call centers and phone systems are powered by The Autodial</p>

                    <p className='text-white fs-6 content' >If you haven’t discovered it yet, The Autodial is the cutting-edge business calling software crafted for data-driven teams. G2 recognizes us as the leading alternative to Aircall! What makes us stand out?</p>
                    <div className='text-center'>
                        <button className='btn btn-primary fs-5 px-4 py-2 my-5' onClick={()=>{navigate('/contact');homeScroll('about')}}>Schedule a Quote</button>
                    </div>
                </div>
                <div className='faq my-5'>
                    <p className='head text-primary fs-2 fw-bold text-center mb-5'>FAQ's</p>
                    <div className="accordion shadow" id="accordionPanelsStayOpenExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                    Who is a user?
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                <div className="accordion-body">
                                    Users work for your company and communicate with your existing and potential customers. You can add or remove users at any time and in just a few clicks. Users are agents, supervisors, analysts, administrators, etc.                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                    How do free trials work?
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
                                <div className="accordion-body">
                                    Simply send us your contact information and we’ll get you set up with a free, no-strings-attached The Autodial trial. You can start dialing immediately and enjoy our full range of features and integrations for two fun-filled weeks.                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                    What are your outbound call prices?
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse">
                                <div className="accordion-body">
                                    Each package includes one standard local or toll-free number for free. Prices of outbound calls vary. Please contact us for more details.                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                    What are your outbound call prices?
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse">
                                <div className="accordion-body">
                                    Each package includes one standard local or toll-free number for free. Prices of outbound calls vary. Please contact us for more details.                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                    What about data safety?
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse">
                                <div className="accordion-body">
                                    The safety of your data is our topmost priority. The Autodial is ISO 27001:2013 and PCI-DSS certified. The Autodial uses third-party data centers with industry-standard certifications (Tier III+ or IV, ISO 27001, PCI-DSS). All facilities include physical protection. Interface is accessed through an encrypted SSL connection. You can read more on our dedicated security page.                            </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
