
// MinimalLayout.jsx
import React from 'react';

const MinimalLayout = ({ children }) => (
  <>
    {children}
  </>
);

export default MinimalLayout;