import React from 'react';
import '../assets/css/termsNconditions.css';

export default function PrivacyPolicy() {
  return (
    <>
    <h1 className='fw-bold text-center mt-5'>Privacy Notice</h1>
    <div className='privacy-policy' style={{textAlign:'justify'}}>
      
       <h5>Last updated: July 25, 2024</h5>
    <p>We value your privacy and are committed to protecting your personal data. This Privacy Notice explains how we collect, use, disclose, and safeguard your information when you visit our website, use our services, or interact with us in any other manner.</p>
    <p>At The Autodial, we understand the importance of maintaining your privacy and are dedicated to protecting your personal information. This Privacy Notice outlines our practices regarding the collection, use, and disclosure of personal data when you use our services, as well as your rights concerning your personal information. We encourage you to read this Privacy Notice carefully to understand how we handle your data.</p>

    <h2>Who We Are</h2>
    <p>The Autodial is a leading provider of VoIP business calling solutions, specializing in enhancing communication and efficiency for businesses worldwide. The Autodial and its affiliates offer a range of services to enhance communication and efficiency for businesses worldwide.</p>

    <h2>Scope of this Privacy Notice</h2>
    <p>This Privacy Notice applies to all personal data processed by The Autodial, including data collected through our website, mobile applications, and other online and offline services. It is relevant to:</p>
    <ul>
        <li>Visitors to our website and users of our services</li>
        <li>Individuals who contact us or interact with us via our communication channels</li>
        <li>Business contacts, such as clients, partners, and suppliers</li>
    </ul>

    <h2>Personal Data We Collect</h2>
    <p>We may collect various types of personal data, including but not limited to:</p>
    <ul>
        <li><b>Contact Information:</b> Name, email address, phone number, and postal address</li>
        <li><b>Account Information:</b> Username, password, and account preferences</li>
        <li><b>Usage Data:</b> Information on how you use our services, including interaction logs and communication content</li>
        <li><b>Technical Data:</b> IP address, browser type, device information, and other technical data</li>
    </ul>

    <h2>Cookies and Similar Technologies Used on Our Websites and in Our Services</h2>
    <p>Our websites and services (jointly “Websites”) use cookies and other similar technologies to automatically collect information about individuals who visit and use our Websites. These are small files that are stored on your device and allow the server that places cookies on your device to recall them. They help us to operate our Websites, provide important features and functionalities such as sign-in and bot protection, enable us to better understand how you use our Websites and to provide tailored advertising.</p>
    <p>We also use third-party cookies that are placed by third parties on our behalf. These parties handle the data they process according to their privacy policies, potentially allowing them to gather and integrate information about your activities across various websites, apps, or online services.</p>

    <h2>Purposes and legal basis for use of cookies</h2>
    <p>Where required, we obtain your consent prior to placing or using optional cookies that are not (i) strictly necessary to provide the requested service (such as a signing in) or (ii) used to facilitate communication.</p>
    <p>We use cookies for the following purposes:</p>
    <ol>
        <li><b>To ensure the technical functionality of our Websites and facilitating communication by using necessary cookies:</b>
            Necessary cookies are required for the proper functioning of our Websites, as they enable core functionalities of our Websites such as marking your data inputs, network management and accessibility. The Autodial processes your personal data on the basis of providing you with a requested service or our legitimate interest to operate the Websites and provide you with our services through these means. These cookies do not require your consent. It is not possible to disable necessary cookies through the Cookie preferences as our Websites might not function properly when you disable this type of cookies. However, if you still wish to do so, you may follow the instructions below in the section Disabling cookies.
        </li>
        <li><b>To collect data about your preferences on the Websites by means of Preference cookies:</b>
            Preference cookies allow us to remember choices you have made in the past, like what language and currency you prefer, to remember your name and e-mail and automatically fill in forms and allow for personalization, such as live chats, videos and the use of social media. We process your personal data based on your consent.
        </li>
        <li><b>To collect analytical data by using analytical cookies:</b>
            Analytical (performance) cookies help us to analyse how you use and navigate our websites and what content is relevant to our users. They are used for performance measurement and improvement. We process your personal data based on your consent.
        </li>
        <li><b>To process your data for marketing purposes by using advertising cookies:</b>
            Advertising cookies help us to deliver tailored and customized advertising on advertising and/or social media platforms. We process your personal data based on your consent.
        </li>
    </ol>
    <p>When you visit our Websites, you shall be informed through a cookie banner placed at the bottom of the Websites that we collect cookies. The banner allows you to manage what kind of cookies can be collected by The Autodial. You may consent to certain categories of cookies and not others. You can change the settings and withdraw your consent at any time through the Cookie settings provided on the bottom of our Websites.</p>
    <p>To protect against malicious activities, some of our Websites may utilize Google reCAPTCHA to verify whether users are humans or robots. This security service relies on cookies classified as necessary by the provider and thus cannot be rejected. reCAPTCHA collects hardware and software information, such as device and application data. Your use of reCAPTCHA is governed by the Google Privacy Policy and Terms of Service.</p>

    <h2>Data processed by cookies</h2>
    <p>Depending on the cookie type, The Autodial may collect the following data via cookies:</p>
    <ul>
        <li>Your city location based on IP address, age, gender, time zone, browser settings, operating system, information about website visits including the URL, search terms, information about what you viewed or searched on our website, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), unique session identifiers, information entered into forms, language preferences, authentication data, user interface customizations, referrer data (where you came from to our website).</li>
    </ul>

    <h2>Similar technologies</h2>
    <p>We use so-called social plugins on our Websites from (i) facebook.com, (ii) instagram.com, (iii) linkedin.com and (iv) youtube.com (“plugins”), which are indicated by their logos on our Websites. When you visit our Websites, your browser establishes a direct connection with the servers on which these plugins run. The content of the plugins is transferred directly by the social networks to your browser, which then integrates it into our Websites.</p>
    <p>Integration of the plugins causes the social networks to receive the information that you have accessed on the corresponding page of our Websites, regardless of whether you interact with the plugin or not. If you are logged in with the social networks, it will be able to assign your visit to your account on these social networks. If you do not want the social networks to gather data about you via our Websites, you must log out of them before visiting our Websites. If you wish to learn about how these social networks process your data, please visit the privacy policy of the respective social network.</p>

    <h2>Disabling cookies</h2>
    <p>If you do not wish cookies to be collected, you may restrict, block or delete the cookies at any time by modifying your browser configuration.</p>
    <p>If you wish to prevent new cookies from being installed or if you wish to delete existing cookies you can find the instructions on the links below based on which browser you are using:</p>
    <ul>
        <li><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a></li>
        <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Firefox</a></li>
        <li><a href="https://support.google.com/chrome/answer/95647?hl=en">Google Chrome</a></li>
        <li><a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">Safari</a></li>
    </ul>
    <p>For mobile devices, you can limit tracking via the privacy setting on your device (disabling the advertising identifier), for instructions please see <a href="https://www.networkadvertising.org/mobile-choice/">https://www.networkadvertising.org/mobile-choice/</a>.</p>
    <p>Furthermore, you can use a third-party tool to opt out of targeted advertising. Available third-party opt-out tools include the Digital Advertising Alliance, the Network Advertising Initiative, and the European Interactive Digital Advertising Alliance (Europe only).</p>
    <p>To opt out from receiving cross-device site advertising (i.e., tracking a user across devices) you can access your device settings or visit and employ the controls described on the NAI’s Mobile Choices page.</p>

    <h2>How We Use Your Personal Data</h2>
    <p>We use your personal data to:</p>
    <ul>
        <li>Provide and improve our services</li>
        <li>Communicate with you about our products, services, and updates</li>
        <li>Ensure the security and functionality of our services</li>
        <li>Comply with legal and regulatory requirements</li>
    </ul>

    <h2>Your Rights and Choices</h2>
    <p>You have the right to access, correct, delete, and restrict the processing of your personal data, as well as the right to object to certain processing activities and to withdraw your consent at any time. You can do so by contacting us at <a href="mailto:support@The Autodial.io">support@The Autodial.io</a>.</p>

    <h2>Access, Use, Storage, and Sharing of Google User Data</h2>

    <h3>Access and Use of Google User Data</h3>
    <p>Our application integrates with Google APIs to enhance the functionality and user experience of our services. Specifically, we access Google user email data for the following purpose:</p>
    <ul>
        <li><b>User Authentication:</b> We use Google OAuth to authenticate users, ensuring secure and seamless access to our application.</li>
    </ul>

    <h3>Storage of Google User Data</h3>
    <p>We store Google user email data securely on our servers to provide uninterrupted service and enhance user experience. The data is encrypted both in transit and at rest using industry-standard encryption protocols. Specifically:</p>
    <ul>
        <li><b>Authentication Tokens:</b> We store OAuth tokens, including the user’s email, to maintain user sessions and provide continuous access without repeated logins.</li>
    </ul>

    <h3>Sharing of Google User Data</h3>
    <p>We do not share Google user email data with third parties except in the following circumstances:</p>
    <ul>
        <li><b>Service Providers:</b> We may share data with trusted service providers who perform services on our behalf, such as cloud storage providers, under strict confidentiality agreements.</li>
        <li><b>Legal Compliance:</b> We may disclose data if required by law or in response to valid legal processes.</li>
    </ul>

    <h3>User Control and Data Deletion</h3>
    <p>Users have control over their data and can:</p>
    <ul>
        <li><b>Revoke Access:</b> Users can revoke our access to their Google data at any time via their Google account settings.</li>
        <li><b>Data Deletion:</b> Users can request the deletion of their Google data from our servers by contacting our support team at <a href="mailto:support@The Autodial.io">support@The Autodial.io</a>. We will respond to such requests within a reasonable timeframe.</li>
    </ul>

    <h3>Security Measures</h3>
    <p>We employ a variety of security measures to protect your data, including:</p>
    <ul>
        <li><b>Encryption:</b> All Google user data is encrypted in transit and at rest.</li>
        <li><b>Access Controls:</b> Access to data is restricted to authorized personnel only.</li>
        <li><b>Regular Audits:</b> We conduct regular security audits to ensure the integrity and security of user data.</li>
    </ul>
    <p>For any further questions regarding how we handle your Google user data, please contact our support team at <a href="mailto:support@The Autodial.io">support@The Autodial.io</a>.</p>

    <h2>Changes to This Privacy Notice</h2>
    <p>We may update this Privacy Notice from time to time to reflect changes in our practices, technologies, legal requirements, and other factors.</p>
    <p>For any questions or concerns regarding this Privacy Notice or our data protection practices, please contact us at <a href="mailto:privacy@The Autodial.io">privacy@The Autodial.io</a>.</p>
    </div>
    </>
  )
}
