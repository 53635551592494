import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { countries,countryDialCodes } from '../assets/modals/contries';
import logo from '../img/logoGif.gif';
import '../assets/css/contact.css';
import theautodial from '../img/theautodialLogo.png'
export default function Contact() {
  const [name, setName] = useState('');
  const [firstname,setFirstName]= useState();
  const [lastName, setLastName]= useState();
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [companyLocation,setCompanyLocation] =useState();
  const [dialCode, setDialCode]= useState();
  const [code, setCode]= useState('');
  const [allert, setAllert] = useState(0);
  const [employees,setEmployees]= useState();
  const [users, setUsers]= useState();
  const [subscribe, setSubscribe]= useState(false);
  const [numbers,setNumbers]=useState(['1-9','10-29','30-49','50-249','250-999','1000+'])
  const location= useLocation();
  const navigate= useNavigate();

  const submitHandler = async () => {
    const fullPhone=code+" "+phone
    await fetch('https://jdtsnautodialback.onrender.com/autodialSubscriber', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({firstName:firstname, lastName, email,phone:fullPhone, employees, users, subscribe})
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // If the response is successful (status code 200), handle it
        setAllert(1);
        alert('Email sent Successfully')
        navigate(-1);
      })
      .catch(error => {
        // Handle errors here
        alert('Please enter registered email')
        console.error('Error:', error.message);
      });
  }

  const databaseManager = async () => {
    console.log('paragraph clicked');
    const fullPhone= code+' '+phone
    await fetch('https://jdtechnicalsolution.com/autodialBackend', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({firstname, lastName, email, phone:fullPhone, employees, users, subscribe })

    })
      .then(response => {
        if (!response.ok) {
          console.log(response)
          throw new Error('Network response was not ok');
        }
        // If the response is successful (status code 200), handle it
        console.log("data sent succesfully",response.json());
      })
      .catch(error => {
        // Handle errors here
        console.log('Error:', error);
      });
  }
  const checkboxHandler= (e)=>{
      if(e.target.checked){
        setSubscribe(true);
      }
      else{
        setSubscribe(false);
      }
  }
  useEffect(() => {
    if (location.pathname === '/contact') {
      document.body.classList.add('setBodyBack')
    } else {
      // Optional: Reset the background when leaving the contact page
      document.body.classList.remove('setBodyBack')
    }
  }, [location.pathname]);
  return (
    <>
      <div className='contact row'>
     {window.innerWidth>576 && <>
      <div className='form-container col-xl-6 col-lg-7 col-md-7 col-12 rounded  shadow bg-white pt-2  pb-5 px-md-4 px-2'>
          <div className='py-3' onClick={()=>{navigate('/')}} style={{cursor:'pointer'}}><img src={logo} width='50' height='50' className='rounded-circle ' style={{top:"5rem"}} alt="/" /> <img className='mt-2' src={theautodial} width='120' alt="/" /></div>
          <input className='me-4 me-lg-5 px-3 py-2 mt-3 name-input' type="text" name="firstName" id="" placeholder='First Name' style={{width:'44%'}} onChange={(e)=>setFirstName(e.target.value)} />
          <input className=' px-3 py-2 name-input' type="text" name="lastName" id="" placeholder='Last Name' style={{width:'44%'}} onChange={(e)=>setLastName(e.target.value)} />
          
          <input className=' px-3 py-2 mt-2' type="text" name="companyEmail" id="" placeholder='Company Email' style={{width:'97%'}} onChange={(e)=>setEmail(e.target.value)} />
       
        <select className='mt-2 select-with-chevron' style={{padding:".6rem",width:'97%'}} name="countries" id="" onChange={(e)=>setCompanyLocation(e.target.value)}>
        <option  disabled selected>Where are you located*</option>
          {countries.map((country, index)=>(
            <option key={index} value={country}>{country}</option>
          ))}
          
        </select>
        <select className='mt-2  me-md-5 me-4 select-with-chevron' style={{padding:".6rem", width:"45%"}} name="countries" id="" onChange={(e)=>setCode(countryDialCodes[e.target.value])}>
        <option  disabled selected>Dial Code*</option>
          {countries.map((country, index)=>(
            <option key={index} value={country}>{country}</option>
          ))}
        </select>
        
        <input className=' ps-5 py-2' type="text" name="phone" id="" placeholder={code!=''?'':'Phone'} style={{width:'44%'}} onChange={(e)=>setPhone(e.target.value)}/>
        <span className='position-absolute visible-code'>{code}</span>
        <select className='mt-2  me-md-5 me-4 select-with-chevron' style={{padding:".6rem", width:"45%"}} name="countries" id="" onChange={(e)=>setEmployees(e.target.value)}>
        <option  disabled selected>No of Employees*</option>
          {numbers.map((country, index)=>(
            <option key={index} value={country}>{country}</option>
          ))}
        </select>
        <select className='mt-2 select-with-chevron' style={{padding:".6rem", width:"44%"}} name="countries" id="" onChange={(e)=>setUsers(e.target.value)}>
        <option  disabled selected>No of Users*</option>
          {numbers.map((country, index)=>(
            <option key={index} value={country}>{country}</option>
          ))}
        </select>
          <p className='mt-3' style={{fontSize:".75rem", }}>By submitting my personal information, I understand and agree that The Autodial may collect, process, and retain my data pursuant to the The Autodial terms and conditions. </p>
          <input className='my-1' type="checkbox" name="subscribe" id="" checked={subscribe===true} onChange={checkboxHandler}/>
          <span className='ms-md-3 ms-1 mb-1 subscribe-text' htmlFor="subscribe"> I would like to subscribe to the monthly newsletter.</span>
          <button className='w-100 btn btn-primary mt-5 mb-2-' onClick={()=>{databaseManager();if(subscribe=="1"){submitHandler()}}}>Book Your Consultation</button>
        </div>
        <div className='col-12 col-md-5 col-xl-6 col-lg-5  contact-text' >
            <p className='text-info'>Experience The Autodial Live</p>
            <p className='head text-white'>Discover the benefits of The Autodial</p>
            <p className='text-primary'><i className="bi bi-check "></i>Begin with a strategic consultation</p>
            <p className='text-primary'><i className="bi bi-check"></i>View a personalized product demonstration</p>
            <p className='text-primary'><i className="bi bi-check"></i>Achieve success with The Autodial</p>
        </div>
        </>}
        {window.innerWidth<=576 &&
          <>
          <div className='form-container col-xl-6 col-lg-7 col-md-7 col-12 shadow bg-white px-md-4 py-3'>
              <div className='logo-container mb-4' onClick={() => { navigate('/') }} style={{ cursor: 'pointer' }} >
                <img src={logo} width='50' height='50' className='rounded-circle ' style={{ top: "5rem" }} alt="/" />
                <img className='mt-2 ms-2' src={theautodial} width='120' alt="/" />
              </div>
              <input className='me-4 me-lg-5 px-3 mt-1 name-input' type="text" name="firstName" id="" placeholder='First Name' style={{ width: '100%' }} onChange={(e) => setFirstName(e.target.value)} />
              <input className=' px-3  name-input mt-3' type="text" name="lastName" id="" placeholder='Last Name' style={{ width: '100%' }} onChange={(e) => setLastName(e.target.value)} />

              <input className=' px-3  mt-3' type="text" name="companyEmail" id="" placeholder='Company Email' style={{ width: '100%' }} onChange={(e) => setEmail(e.target.value)} />

              <select className='mt-3 select-with-chevron' style={{ padding: ".6rem", width: '100%', height: '48px' }} name="countries" id="" onChange={(e) => setCompanyLocation(e.target.value)}>
                <option disabled selected>Where are you located*</option>
                {countries.map((country, index) => (
                  <option key={index} value={country}>{country}</option>
                ))}

              </select>
              <select className='mt-3  me-md-5 me-4 select-with-chevron' style={{ padding: ".6rem", width: "20%", height: '48px' }} name="countries" id="" onChange={(e) => setCode(countryDialCodes[e.target.value])}>
                <option disabled selected>*</option>
                {countries.map((country, index) => (
                  <option key={index} value={country}>{countryDialCodes[country]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{country}</option>
                ))}
              </select>

              <input className=' ps-2 ' type="text" name="phone" id="" placeholder={code != '' ? '' : 'Phone'} style={{ width: '72.5%' }} onChange={(e) => setPhone(e.target.value)} />
              {/* <span className='position-absolute dial-code'>{code}</span> */}
              <select className='mt-3  me-md-5 me-4 select-with-chevron' style={{ padding: ".6rem", width: "100%", height: '48px' }} name="countries" id="" onChange={(e) => setEmployees(e.target.value)}>
                <option disabled selected>No. of Employees*</option>
                {numbers.map((country, index) => (
                  <option key={index} value={country}>{country}</option>
                ))}
              </select>
              <select className='mt-3 select-with-chevron' style={{ padding: ".6rem", width: "100%", height: '48px' }} name="countries" id="" onChange={(e) => setUsers(e.target.value)}>
                <option disabled selected>No of Users*</option>
                {numbers.map((country, index) => (
                  <option key={index} value={country}>{country}</option>
                ))}
              </select>
              <br />
              <input className='mt-4' type="checkbox" name="subscribe" id="" checked={subscribe === true} onChange={checkboxHandler} />
              <span className='ms-2 mb-1' htmlFor="subscribe" style={{ fontSize: ".9rem", position: 'relative', top: '-.3rem' }}> I would like to subscribe to the monthly &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;newsletter.</span>
              <p className='mt-3' style={{ fontSize: ".75rem", color: '#8c8c8c' }}>By submitting my personal information, I understand and agree that The Autodial may collect, process, and retain my data pursuant to the The Autodial terms and conditions. </p>
              <button className='w-100 btn btn-primary mt-md-5 mt-2 mb-md-2 mb-5 ' onClick={() => { databaseManager(); if (subscribe == "1") { submitHandler() } }}>Book Your Strategy Call</button>
            </div>
            <div className='col-12 col-md-5 col-xl-6 col-lg-5  contact-text mt-4' >
              <p className='text-info'>Experience The Autodial Live</p>
              <p className='head text-white'>Discover the benefits of The Autodial</p>
              <p className='text-warning'><i className="bi bi-check "></i>Begin with a strategic consultation</p>
              <p className='text-warning'><i className="bi bi-check"></i>View a personalized product demonstration</p>
              <p className='text-warning'><i className="bi bi-check"></i>Achieve success with The Autodial</p>
            </div>
        </>}
      </div>
    </>
  );
}
