import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import jdLogo from '../img/theautodialLogo.png';
import { homeScroll } from '../smoothScroll';
import autodialLogo from '../img/logoGif.gif'
export default function Newnav() {
    const [navColor, setNavColor] = useState('rgb(0,0,0,.1)');
    const [linkColor, setLinkColor] = useState('white');
    const navigate = useNavigate();
    const location = useLocation();

    const handlemouseover = () => {
        const product = document.getElementById('product');
        product.style.zIndex = '11'
        product.style.opacity = '1'
        if (window.innerWidth > 576) {
            product.style.top = '44px'
        }
        else {
            product.style.top = '50px'
        }
    }
    const handlemouseleave = () => {
        const product = document.getElementById('product');
        product.style.zIndex = '1'
        product.style.top = '-550px'
    }
    useEffect(() => {
        if (window.innerWidth < 728) {
            setNavColor('rgba(15,195,250,.8)');
            setLinkColor('black')
        }
    });
    const handleScroll = () => {
        const navbar = document.getElementById('navbar');
        if (window.location.pathname !== '/contact') {
            if (window.scrollY >= 690) {

                navbar.classList.add('scrolledNav');
                navbar.classList.remove('nonScrolledNav')
                console.log('out')
            }

            else if (window.scrollY < 690 && window.scrollY > 50) {
                navbar.classList.remove('scrolledNav');
                navbar.classList.add('nonScrolledNav');
                console.log('in')
            }
            else {
                navbar.classList.remove('scrolledNav');
                navbar.classList.remove('nonScrolledNav')
            }
        }

    };
    const setActive = (e,f) => {

        const home = document.getElementById('home');
        const products = document.getElementById('products');
        const pricing = document.getElementById('pricing');
        const login = document.getElementById('login');
        const contact = document.getElementById('contact');
        console.log(e.target.classList);
        home.classList.remove('active');
        products.classList.remove('active');
        pricing.classList.remove('active');
        login.classList.remove('active');
        contact.classList.remove('active');
        if(!f){
            e.target.classList.add('active');
        }
        else{
            if(f=='products') products.classList.add('active');
            else home.classList.add('active');
        }
        console.log(e.target.classList);


    }
    const hidetoggler=()=>{
        const toggler= document.getElementById('toggler');
        toggler.click();
    }
    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [])

    return (
        <>
            <div className="container-fluid position-relative p-0">
                <nav id="navbar" className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-md-3 py-1 py-lg-0">
                    <Link to="/" onClick={(e)=>{homeScroll('about');setActive(e)}} className="navbar-brand p-0 d-flex">
                        {/* <h1 className="m-0"><i className="fa fa-map-marker-alt me-3"></i>Travela</h1> */}
                        <img className='rounded logo'src={autodialLogo} alt="Logo" /><img className='rounded logo ms-2'src={jdLogo} alt="Logo" />
                    </Link>
                    <button id='toggler' className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="fa fa-bars"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav ms-auto py-0">
                            <Link id="home" to='/' onClick={(e) => { homeScroll('about'); setActive(e,'home');hidetoggler()}} className="nav-item nav-link active">Home</Link>
                            <div className="nav-item dropdown">
                                <Link id="products" to="#" onClick={(e) => {setActive(e)}} className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Our Products</Link>
                                <div className="dropdown-menu m-0">
                                    <Link to="/inbound-call-center" onClick={(e) => { homeScroll('about'); setActive(e,'products'); hidetoggler();}} className="dropdown-item">Inbound Call Center</Link>
                                    <Link to="/outbound-call-center" onClick={(e) => { homeScroll('about'); setActive(e,'products') ;hidetoggler()}} className="dropdown-item">Outbound Call Center</Link>
                                    <Link to="/bps" onClick={(e) => { homeScroll('about'); setActive(e,'products');hidetoggler()}} className="dropdown-item">Business Phone System</Link>
                                </div>
                            </div>
                            <Link id="pricing" to="/pricing" onClick={(e) => { homeScroll('about'); setActive(e);hidetoggler() }} className="nav-item nav-link">Pricing</Link>
                            <Link id="login" to="https://service.theautodial.com/theautodial/welcome_manager.php" onClick={(e) => { homeScroll('about'); setActive(e)}} className="nav-item nav-link" style={{ textDecorationLine: 'none', color: "" }}><span className=' btn-primary' style={{ backgroundColor: '' }}>Login</span><small className="ms-2"><i className="fa fa-sign-in-alt" style={{ color: '' }}></i></small></Link>
                            <Link id="contact" to="/contact" onClick={(e) => { homeScroll('about'); setActive(e)}} className="nav-item nav-link">Contact for sale</Link>
                        </div>

                    </div>
                </nav>
            </div>

        </>
    )
}
