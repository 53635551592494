export const countries = ["Australia", "Belgium", "Canada", "New Zealand", "Spain", "United Kingdom", "USA","India"];

export const countryDialCodes = {
    "Australia": "+61",
    "Belgium": "+32",
    "Canada": "+1",
    "India": "+91",
    "New Zealand": "+64",
    "Spain": "+34",
    "United Kingdom": "+44",
    "USA": "+1"
};